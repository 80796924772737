.img-bg #offline {
  text-align: center;
  font-weight: 600;
}
.img-bg .Container {
  background-color: azure !important;
}
.img-bg .card {
  /* border: 4px solid #000; */
  /* width: 200px;
  height: 200px; */
  border-width: 10px;
  border-style: solid;
  border-image: linear-gradient(to right, #432b8d, #9a55ff, #9a55ff, #432b8d) 1;
  padding: 30px 30px;
  border-width: 4px 0px;
  width: fit-content;
}
/* .Parent {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  height: 100vh;
} */
.img-bg .btn-success {
  color: #fff;
  background-color: #3e3935;
  border-color: #3e3935;
}
.img-bg .btn-success:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.img-bg ::marker {
  color: #000;
}
.img-bg li#des {
  font-weight: 500;
  color: #000;
}
.img-bg span#siteName {
  font-weight: 600;
}
/* span#cont {
color: #000;
} */
.img-bg h6.text-left.mt-4.font-weight-bold.mb-0 {
  font-weight: 700;
}
