
.menu-toggle {
    position: relative;
    width: 40px;
    height: 40px;
    cursor: not-allowed;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-toggle::before {
    content: '';
    position: absolute;
    width: 24px;
    height: 4px;
    background: #000;
    box-shadow: 0 8px 0 #000,
                0 -8px 0 #000;
}

/* profile menu */

.profile {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    text-align: end;
    background-color: #fff;
    padding: 10px;
}

.profile h3 {
    text-align: end;
    line-height: 1;
    margin-bottom: 4px;
    font-weight: 600;
    font-size: 20px;
}
li.nav-item.dropdown.dropdown-user-setting {
    color: #000;
}

.profile p {
    line-height: 1;
    font-size: 14px;
    opacity: .6;
}

.profile .img-box {
    position: relative;
    width: 48px;
    height: 48px;
    /* border-radius: 50%; */
    overflow: hidden;
}

.profile .img-box img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* menu (the right one) */

.menu {
    position: absolute;
    top: calc(100% + 24px);
    right: 16px;
    width: 200px;
    min-height: 100px;
    background: #fff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .2);
    opacity: 0;
    transform: translateY(-10px);
    visibility: hidden;
    transition: 300ms;
}

.menu::before {
    content: '';
    position: absolute;
    top: -10px;
    right: 14px;
    width: 20px;
    height: 20px;
    background: #fff;
    transform: rotate(45deg);
    z-index: -1;
}

.menu.active {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
}

/* menu links */

.menu ul {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 10;
    background: #fff;
}

.menu ul li {
    list-style: none;
}

.menu ul li:hover {
    background: #eee;
}
ul.menu-list {
    padding-left: 0px;
}
.menu ul li a {
    text-decoration: none;
    color: #000 !important;
    display: flex;
    align-items: center;
    padding: 15px 20px;
    gap: 6px;
}

.menu ul li a i {
    font-size: 1.2em;
}
a.logout-btn {
    margin-left: -3px;
    gap: 10px !important;
}