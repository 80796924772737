
.top-bar {
  background: #432b8d;
  padding: 0px;
}
.top-bar a {
  color: #fff !important;
  outline: none;
  font-weight: bolder;
  font-size: 16px;
}

.top-bar i {
  background: #6C44C7;
  padding: 8px 7px;
  margin-right: 6px;
}

